<template>
    <v-snackbar
        v-model="parameters.snackbarModel.show"
        :timeout="parameters.snackbarModel.timeout"
        dark
        :color="parameters.secondaryColor"
        top
    >
        {{ parameters.snackbarModel.text }}
        <template v-slot:action="{ attrs }">
            <v-btn
                icon
                color="white"
                text
                v-bind="attrs"
               @click="parameters.snackbarModel.show = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    },
};
</script>

<style>
</style>